<template>
	<div style="display:block" class="layer">
		<div class="layer__dim"></div>
		<div class="layer__full">
			<div class="layer__fullbox">
				<div class="layer__fullheader">
					<nav class="nav">
						<div class="nav__wrap">
							<a class="nav__btn nav__btn--prev" @click="goBack">
								<i class="icon icon-close">닫기</i><!-- 재번역체크 -->
							</a>
							<p class="nav__item">
								<span class="nav__text">
									{{t('10679')}}
								</span>
							</p>
							<a href="#" class="nav__btn">
							</a>
						</div>
					</nav>
				</div>
				<div class="layer__fullbody">
					<section class="member">
						<div class="box">
							<h3 class="member__title">{{t('10680')}}</h3>
							<ul class="member__list">
								<li>
									<div class="member__type">
										{{t('10588')}}
										<small> (만 18세 이상)</small><!-- 재번역체크 -->
									</div>
									<div class="member__number">
										<div class="amount">
											<button type="button" class="btn-amount btn-amount--minus" :class="{'btn-disabled': adultCount === 1}" @click="decreaseAdultCount">-</button>
											<span class="amount__count active">
														{{adultCount}}
													</span>
											<button type="button" class="btn-amount btn-amount--plus" :class="{'btn-disabled': adultCount === 30}" @click="increaseAdultCount">+</button>
										</div>
									</div>
								</li>
								<li>
									<div class="member__type">
										{{t('10589')}}
										<small> (만 17세 이하)</small><!-- 재번역체크 -->
									</div>
									<div class="member__number">
										<div class="amount">
											<button type="button" class="btn-amount btn-amount--minus" :class="{'btn-disabled': childCount === 0}" @click="decreaseChildCount">-</button>
											<span class="amount__count" :class="{'active': childCount > 0 }">
													{{childCount}}
													</span>
											<button type="button" class="btn-amount btn-amount--plus" :class="{'btn-disabled': childCount === 30}" @click="increaseChildCount">+</button>
										</div>
									</div>
								</li>
							</ul>
							<div id="navigation" class="navigation navigation--noborder">
								<div class="btn btns--centered">
									<button @click="handleSubmit" type="button" class="btn btn-submit">
										<span>{{t('10588')}}{{adultCount}}{{t('10681')}}</span> <span>{{t('10589')}} {{childCount}}{{t('10681')}}</span> 선택<!-- 재번역체크 -->
									</button>
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ref, watchEffect } from 'vue';
import { useStore } from 'vuex'
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n' 
import i18n from "@/i18n"; 
export default {
	setup() {
		const store = useStore();
		const router = useRouter();
		const adultCount = ref(2);
		const childCount = ref(0);
		const { t }= useI18n() 

		watchEffect(() => {
			if(store.state.mhm01.selectedMember !== null) {
				adultCount.value = store.state.mhm01.selectedMember.adultCount;
				childCount.value = store.state.mhm01.selectedMember.childCount;
			}
		})

		const handleSubmit = () => {
			store.commit("mhm01/SET_MEMBER", {member: {adultCount, childCount}})
			router.back();
		}
		
		const decreaseAdultCount = () => {
			adultCount.value = adultCount.value > 1 ? adultCount.value - 1 : adultCount.value;
		}

		const increaseAdultCount = () => {
			adultCount.value = adultCount.value < 30 ? adultCount.value + 1 : adultCount.value;
		}

		const decreaseChildCount = () => {
			childCount.value = childCount.value > 0 ? childCount.value - 1 : childCount.value;
		}

		const increaseChildCount = () => {
			childCount.value = childCount.value < 30 ? childCount.value + 1 : childCount.value;
		}

		return {
			adultCount,
			childCount,
			handleSubmit,
			decreaseAdultCount,
			increaseAdultCount,
			decreaseChildCount,
			increaseChildCount,
			t,  //번역필수 모듈
		  i18n, 
			goBack() {
				router.back();
			}
		}
	}
}
</script>